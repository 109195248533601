import React, { FunctionComponent, useState, MouseEventHandler, ChangeEventHandler, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { breakPoint } from '../conf/media';
import { neutral, light, primary } from '../conf/colors';
import { User } from '@auth0/auth0-spa-js';
import Button from '../components/button';
import { updatePassword } from '../services/users';
import { useSnackbar } from '../hooks/snackbar';

const Section = styled.div`
  padding-left: 100px;
  padding-top: 65px;
  padding-bottom: 25px;
  font-size: 18px;

  @media (max-width: ${breakPoint}px) {
    padding-top: 26px;
    padding-bottom: 14px;
    padding-left: 52px;
  }
`;

const Dashboard = styled.div`
  padding: 36px 36px 0 36px;
  background-color: ${neutral};
  border: 1px solid #dfebff;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${breakPoint}px) {
    justify-content: space-evenly;
  }
`;

const Card = styled.div`
  width: 296px;
  margin-bottom: 36px;
  margin-right: 15px;
  margin-left: 15px;
  position: relative;
`;

const CardTitle = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 23px;
  font-size: 14px;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  background-color: ${light};
  border: 1px solid #9d9ea1;
  border-radius: 5px;
  padding: 16px 52px;
`;

const FormItem = styled.div`
  padding-top: 16px;

  &:last-child {
    padding-bottom: 16px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 20px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

type Props = {
  user: User;
};

const Profile: FunctionComponent<Props> = ({user}) => {
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const { setMessage, setType } = useSnackbar();
 
  const handleNewPasswordInput: ChangeEventHandler<HTMLInputElement> = useCallback(({currentTarget}) => {
    setNewPassword(currentTarget.value)
  }, [setNewPassword])

  const handleConfirmPasswordInput: ChangeEventHandler<HTMLInputElement> = useCallback(({currentTarget}) => {
    setConfirmPassword(currentTarget.value)
  }, [setConfirmPassword]);

  const handlePasswordUpdate: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    if (newPassword !== confirmPassword) {
      setType('error')
      setMessage('New and confirm passwords don\'t match!');
    } else {
      updatePassword(user.sub as string, newPassword)
      .then(() => {
        setType('info')
        setMessage('Your password have been updated.');
      })
      .catch((e) => {
        setType('error')
        setMessage(e);
      });
    }
  }, [newPassword, confirmPassword, user.sub, setType, setMessage])
  return (
    <>
      <Section>My Profile</Section>
      <Dashboard>
      <Card>
        <CardTitle>User Info</CardTitle>
        <CardContent>
          <FormItem>
            <label>
              First Name
              <Input type="text"  value={user?.given_name} disabled/>
            </label>
          </FormItem>
          <FormItem>
            <label>
              Last Name
              <Input type="text"  value={user?.family_name} disabled/>
            </label>
          </FormItem>
          <FormItem>
            <label>
              Email
              <Input value={user?.email} disabled/>
            </label>
          </FormItem>
        </CardContent>
      </Card>
      <Card>
        <CardTitle>Update Password</CardTitle>
        <CardContent>
          <FormItem>
            <label>
              New Password
              <Input type="password" value={newPassword} onChange={handleNewPasswordInput} />
            </label>
          </FormItem>
          <FormItem>
            <label>
              Confirm Password
              <Input type="password" value={confirmPassword} onChange={handleConfirmPasswordInput} />
            </label>
          </FormItem>
          <FormItem>
            <Button onClick={handlePasswordUpdate} color={primary}>Save</Button>
          </FormItem>
          </CardContent>
        </Card>
      </Dashboard>
    </>
  );
}

export default Profile;
