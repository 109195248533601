import React from "react";
import {
  Link,
  useMatch,
  useResolvedPath
} from "react-router-dom";
import type { LinkProps } from "react-router-dom";
import styled from 'styled-components';
import { light, secondary } from '../conf/colors';

const StyledLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  &:focus, &:hover, &:visited, &:link, &:active {
      text-decoration: none;
  }
  &:hover {
      text-decoration: underline;
  }
  margin-right: 28px;
  &:last-child {
    margin-right: 0;
  }
`;

const NavLink = ({ children, to, ...props }: LinkProps) => {
    const resolved = useResolvedPath(to);
    const match = useMatch({ path: resolved.pathname, end: true });
    return (
        <StyledLink
            style={{ color: match ? light : secondary }}
            to={to}
            {...props}
        >
            {children}
        </StyledLink>
    );
}

export default NavLink;
  