import React, { FunctionComponent, useCallback } from 'react';
import styled from 'styled-components';
import CydeoLogo from '../assets/logos/cydeo.svg';
import { primary, lightShadow } from '../conf/colors';
import { breakPoint } from '../conf/media';
import NavLink from './nav-link';
import { LogoutOptions } from '@auth0/auth0-react';

const Container = styled.div`
  background-color: ${primary};
  height: 65px;
  box-shadow: 0px 5px 5px ${lightShadow};
  padding: 0 70px;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${breakPoint}px) {
    padding: 0 52px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 14px;
`;

const Divider = styled.div`
  margin-right: 26px;
`;


type Props = {
  logout: (opts: LogoutOptions) => void;
};

const Header: FunctionComponent<Props> = ({logout}) => {
  const handleLogoClick = useCallback(() => { 
    window.open(process.env.HOME_URL, '_blank');
  }, []);

  return (
    <>
    <Container>
      <LogoContainer onClick={handleLogoClick}>
        <img src={CydeoLogo} alt='cydeo logo'/>
      </LogoContainer>
        <Menu>
          <NavLink to="/">Apps</NavLink>
          <NavLink to="/profile">Profile</NavLink>
          <Divider>|</Divider>
          <NavLink  onClick={() => logout({ returnTo: `${window.location.origin}/apps` })} to="/logout">Log Out</NavLink>
        </Menu>
    </Container>
     </>
  );
}

export default Header;