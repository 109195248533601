export const getRoles = async (id: string): Promise<[string]> => {
    try {
        const endocedId = encodeURI(id);
        const res = await fetch(`${process.env.API_URL}/users/${endocedId}/roles`);
        if (res.ok) {
            const body = await res.json();
            return Promise.resolve(body);
        }
    } catch (e) {
        return Promise.reject(e);
    }
    return Promise.reject('Failed to get user roles');
};

export const updatePassword = async (id: string, newPassword: string): Promise<void> => {
    try {
        const endocedId = encodeURI(id);
        const res = await fetch(`${process.env.API_URL}/users/${endocedId}/password`, {
            method: 'PATCH',
            body: JSON.stringify({newPassword})
        });
        if (res.ok) {
            return Promise.resolve();
        } else {
            const body = await res.json();
            return Promise.reject(body?.error);
        }
    } catch (e) {
        return Promise.reject(e);
    }
};