export type App = {
    id: string,
    name: string,
    type: string,
    allowedRoles: string[],
    signInUrl: string,
    logoUrl: string
};

export const getApps = async (id: string): Promise<[App]> => {
    try {
        const endocedId = encodeURI(id);
        const res = await fetch(`${process.env.API_URL}/users/${endocedId}/apps`);
        if (res.ok) {
            const body = await res.json();
            return Promise.resolve(body);
        }
    } catch (e) {
        return Promise.reject(e);
    }
    return Promise.reject('Failed to get user roles');
};