import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from "react-router-dom";
import Router from "./router";
import { SnackbarProvider } from './providers/snackbar';
import './index.css';

ReactDOM.render(
  <Auth0Provider
    domain={process.env.DOMAIN as string}
    clientId={process.env.CLIENT_ID as string}
    redirectUri={`${window.location.origin}/apps`} 
  >
    <BrowserRouter basename='/apps'>
      <SnackbarProvider>
        <Router />
      </SnackbarProvider>
    </BrowserRouter>
  </Auth0Provider>,
  document.body.appendChild(document.createElement('div'))
);
