import React, { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import AppCard from '../components/app-card';
import { breakPoint } from '../conf/media';
import logos from '../conf/logos';
import { neutral } from '../conf/colors';
import { getApps, App } from '../services/apps';
import { getRoles } from '../services/users';
import { User } from '@auth0/auth0-spa-js';


const Section = styled.div`
  padding-left: 100px;
  padding-top: 65px;
  padding-bottom: 25px;
  font-size: 18px;

  @media (max-width: ${breakPoint}px) {
    padding-top: 26px;
    padding-bottom: 14px;
    padding-left: 52px;
  }
`;

const Dashboard = styled.div`
  padding: 36px 36px 0 36px;
  background-color: ${neutral};
  border: 1px solid #dfebff;
  display: flex;
  flex-wrap: wrap;
  
  @media (max-width: ${breakPoint}px) {
    justify-content: space-evenly;
  }
`;

type Props = {
  user: User;
};

const MyApps: FunctionComponent<Props> = ({user}) => {
  const [apps, setApps] = useState<App[]>([]);

  useEffect(() => {
    getApps(user.sub as string)
      .then((a) => {
        const withLogo = a.map(app => ({
            ...app,
            logoUrl: (logos as any)[app.type]
          }));
        setApps(withLogo);
      });
  }, [setApps, user.sub]);

  return (
    <>
      <Section>My Apps</Section>
      <Dashboard>
        {Object.entries(apps).map(([i, a]) => <AppCard key={i} {...a} />)}
      </Dashboard>
    </>
  );
};

export default MyApps;
