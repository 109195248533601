import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { danger, primary, lightShadow } from '../conf/colors';

export type Props = {
    message: string
    type: "error" | "info"
}

const Container = styled.div<{type: string}>`
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 8px;
    font-size: 14px;
    background-color: ${(props) => props.type === 'error' ? danger : primary};
    box-shadow: 0px 5px 5px ${lightShadow};
    border-radius: 5px;
`;

const Snackbar: FunctionComponent<Props> = ({message, type}) => (
    <Container type={type}>{message}</Container>
);

export default Snackbar;