import React, { useEffect, VoidFunctionComponent } from "react";
import { Routes, Route } from "react-router-dom";
import MyApps from "./pages/my-apps";
import Profile from "./pages/profile";
import { useAuth0 } from "@auth0/auth0-react";
import Spinner from './components/spinner';
import Header from './components/header';
import { neutral } from './conf/colors';
import styled from 'styled-components';

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${neutral};
`;

const Router: VoidFunctionComponent = () => {
    const { user, isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0();
    useEffect(() => {
      if (!isLoading && !isAuthenticated) loginWithRedirect()
    }, [isAuthenticated, loginWithRedirect, isLoading]);
    return (
        <>
            { 
                (isAuthenticated && user) &&
                <>
                <Header logout={logout}/>
                <Routes>
                    <Route index element={<MyApps user={user}/>} />
                    <Route path="profile" element={<Profile user={user}/>} />
                </Routes>
                </>
            }
            {
                isLoading && <SpinnerContainer><Spinner/></SpinnerContainer>
            }
        </>
    );
}

export default Router;
