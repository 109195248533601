import React, { createContext, Dispatch, FunctionComponent, ReactNode, SetStateAction, useEffect, useState, VoidFunctionComponent } from 'react';
import Snackbar from '../components/snackbar';

type SnackbarContext = {
    setMessage: Dispatch<SetStateAction<string>>,
    setType: Dispatch<SetStateAction<'error' | 'info'>>,
}

const defaultContext = {
    setMessage: (() => {}) as any,
    setType: (() => {}) as any
}

export const GlobalContext = createContext<SnackbarContext>(defaultContext);

type Props = {
    children: ReactNode
}

export const SnackbarProvider: FunctionComponent<Props> = ({children}) => {
    const [message, setMessage] = useState<string>('');
    const [type, setType] = useState<'error' | 'info'>('info');
    const [show, setShow] = useState(false);

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (message) {
            setShow(true);
            interval = setInterval(() => setShow(false), 8000);
        }
        return () => {
            if (interval) {
                clearInterval(interval)
            }
        }

    }, [message]);

    return (
        <GlobalContext.Provider value={{setMessage, setType}}>
            {children}
            {show && <Snackbar message={message} type={type} />}
        </GlobalContext.Provider>
    )
}