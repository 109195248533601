import React, { VoidFunctionComponent } from 'react';
import styled from 'styled-components';
import { primary, secondary } from '../conf/colors';

const Loader = styled.circle`
animation: spinners-react-circular 3s linear infinite;
@keyframes spinners-react-circular {
    0% { stroke-dashoffset: 306; }
    50% { stroke-dasharray: 40, 134; }
    100% {
    stroke-dasharray: 1, 174;
    stroke-dashoffset: 132;
    }
}
`;

const Container = styled.div`
  height: 50px;
  width: 50px;
`;
   
const Spinner: VoidFunctionComponent = () => (
  <Container>
    <svg fill="none" viewBox="0 0 66 66">
      <circle
        cx="33"
        cy="33"
        fill="none"
        r="28"
        stroke={primary}
        strokeWidth="3px"
      />
      <Loader
        cx="33"
        cy="33"
        fill="none"
        r="28"
        stroke={secondary}
        strokeDasharray="1, 174"
        strokeDashoffset="306"
        strokeLinecap="round"
        strokeWidth="3px"
      />
    </svg>
  </Container>
);

export default Spinner;