import React, { FunctionComponent, useCallback } from 'react';
import styled from 'styled-components';
import { light } from '../conf/colors'

const Container = styled.div`
  width: 296px;
  margin-bottom: 36px;
  margin-right: 15px;
  margin-left: 15px;
  position: relative;
`;

const Card = styled.div`
  height: 120px;
  width: 100%;
  background-color: ${light};
  border: 1px solid #9d9ea1;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Logo = styled.img`
  width: 120px;
`;

const Name = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 23px;
  font-size: 14px;
`;

type Props = {
    name: string
    logoUrl: string
    signInUrl: string;
}

const AppCard: FunctionComponent<Props> = ({name, signInUrl, logoUrl}) => {
  const openApp = useCallback(() => {
    window.open(signInUrl);
  }, [signInUrl])
  return (<>
    <Container onClick={openApp}>
      <Card>
        <Logo src={logoUrl} alt={`${name} logo`}/>
      </Card>
      <Name>{name}</Name>
    </Container>
  </>);
}

export default AppCard;
