import React from 'react';
import styled from 'styled-components';
import { light } from '../conf/colors';

type Props = {
  children?: HTMLCollection | string,
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = styled.button<Props>`
  border-radius: 50px;
  width: 100%;
  padding: 12px 20px;
  text-decoration: none;
  border: none;
  background-color: ${(props) => props.color};
  color: ${light};
  font-weight: 600;
  font-size: 15px;
  &:hover {
    background-color: #9379FE;
  }
`;

export default Button;