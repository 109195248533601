import JiraLogo from "../assets/logos/jira.svg"
import CanvasLogo from "../assets/logos/canvas.svg"
import DiscordLogo from "../assets/logos/discord.svg"
import GithubLogo from "../assets/logos/github.svg"
import ZoomLogo from "../assets/logos/zoom.svg"
import CydeoLearningLogo from "../assets/logos/cydeo-learning.svg"
import MyReferralsLogo from "../assets/logos/my-referrals.svg"

const logos = {
    jira: JiraLogo,
    canvas: CanvasLogo,
    discord: DiscordLogo,
    github: GithubLogo,
    zoom: ZoomLogo,
    'cydeo-learning': CydeoLearningLogo,
    'my-referrals': MyReferralsLogo
}

export default logos;